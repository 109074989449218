import Vue from "vue";
import VueRouter from "vue-router";
import { isMobile } from "@/utils";
import pcRoutes from "./pc";
import mbRoutes from "./mb";

Vue.use(VueRouter);
const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: isMobile() ? mbRoutes : pcRoutes,
  // routes: pcRoutes,
});

export default router;
