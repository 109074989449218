export default [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/pc/home/index"), //首页
  },
  {
    path: "/new-wood",
    name: "NewWood",
    component: () => import("@/views/pc/wood/index"), //新木
  },
  {
    path: "/leaf",
    name: "Leaf",
    component: () => import("@/views/pc/leaf/index"), //叶子
  },
];
