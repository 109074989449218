<template>
  <div id="app">
    <div class="app-container">
      <transition enter-active-class="fadeIn animated">
        <router-view />
      </transition>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  .app-container {
    height: 100%;
  }
}
</style>
