import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "animate.css";

import "@/assets/css/index.scss";
import "amfe-flexible/index.js";

import VueAwesomeSwiper from "vue-awesome-swiper";

// import style (<= Swiper 5.x)
import "swiper/css/swiper.css";

Vue.use(VueAwesomeSwiper);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
