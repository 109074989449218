export function isMobile() {
  if (
    window.navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  ) {
    return true;
  }
  return false;
}

export const EventUtil = {
  addHandler: function (element, type, handler) {
    if (element.addEventListener) element.addEventListener(type, handler, { passive: false });
    else if (element.attachEvent) element.attachEvent("on" + type, handler);
    else element["on" + type] = handler;
  },
  removeHandler: function (element, type, handler) {
    if (element.removeEventListener) element.removeEventListener(type, handler, { passive: false });
    else if (element.detachEvent) element.detachEvent("on" + type, handler);
    else element["on" + type] = handler;
  },
  /**
   * 监听触摸的方向
   * @param target            要绑定监听的目标元素
   * @param isPreventDefault  是否屏蔽掉触摸滑动的默认行为（例如页面的上下滚动，缩放等）
   * @param upCallback        向上滑动的监听回调（若不关心，可以不传，或传false）
   * @param rightCallback     向右滑动的监听回调（若不关心，可以不传，或传false）
   * @param downCallback      向下滑动的监听回调（若不关心，可以不传，或传false）
   * @param leftCallback      向左滑动的监听回调（若不关心，可以不传，或传false）
   */
  listenTouchDirection: function (target, isPreventDefault, upCallback, rightCallback, downCallback, leftCallback) {
    this.addHandler(target, "touchstart", handleTouchEvent);
    this.addHandler(target, "touchend", handleTouchEvent);
    this.addHandler(target, "touchmove", handleTouchEvent);
    var startX;
    var startY;
    function handleTouchEvent(event) {
      switch (event.type) {
        case "touchstart":
          startX = event.touches[0].pageX;
          startY = event.touches[0].pageY;
          break;
        case "touchend":
          var spanX = event.changedTouches[0].pageX - startX;
          var spanY = event.changedTouches[0].pageY - startY;

          if (Math.abs(spanX) > Math.abs(spanY)) {
            //认定为水平方向滑动
            if (spanX > 30) {
              //向右
              if (rightCallback) rightCallback();
            } else if (spanX < -30) {
              //向左
              if (leftCallback) leftCallback();
            }
          } else {
            //认定为垂直方向滑动
            if (spanY > 30) {
              //向下
              if (downCallback) downCallback();
            } else if (spanY < -30) {
              //向上
              if (upCallback) upCallback();
            }
          }

          break;
        case "touchmove":
          //阻止默认行为
          if (isPreventDefault) event.preventDefault();
          break;
      }
    }
  },
};
